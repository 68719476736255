import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 业主端 分页获取施工计划管理表
 * @param {Object} data
 * @returns
 */
export function getWorkdayManagePage (data) {
  return request.post('/ooh-msp/v1/workdayManage/getWorkdayManagePage', qs.stringify(data))
}

/**
 * 创建施工计划管理表
 * @param {Object} data
 * @returns
 */
export function createWorkdayManage (data) {
  return request.post('/ooh-msp/v1/workdayManage/createWorkdayManage', qs.stringify(data))
}

// /**
//  * 完成申请
//  * @param {Object} data
//  * @returns
//  */
// export function finishApply (data) {
//   return request.post('/ooh-msp/v1/workdayManage/finishApply', qs.stringify(data))
// }

/**
 * 施工计划按指定维度汇总
 * @param {Object} data
 * @returns
 */
export function getTaskitemGather (data) {
  return request.post('/ooh-msp/v1/workdayManage/getTaskitemGather', qs.stringify(data))
}

/**
 * 根据施工管理表id获取施工计划明细
 * @param {Object} data
 * @returns
 */
export function getWorkdayPage (data) {
  return request.post('/ooh-msp/v1/workdayManage/getWorkdayPage', qs.stringify(data))
}

/**
 * 更新状态
 * @param {Object} data
 * @returns
 */
export function updateStatus (data) {
  return request.post('/ooh-msp/v1/workdayManage/updateStatus', qs.stringify(data))
}

/**
 * 获取施工计划详情
 * @param {Object} data
 * @returns
 */
export function getWorkdayManageInfo (data) {
  return request.post('/ooh-msp/v1/workdayManage/getWorkdayManageInfo', qs.stringify(data))
}

/**
 * 将施工计划表移除施工计划申请表
 * @param {Object} data
 * @returns
 */
export function removeTable (data) {
  return request.post('/ooh-msp/v1/workdayManage/removeTable', qs.stringify(data))
}
