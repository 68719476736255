<template>
  <div>
    <Modal
      v-model="modifyModal"
      width="400"
    >
      <p
        slot="header"
        class="text-center"
      >调整作业日期</p>
      <div>
        <DatePicker
          v-model="actionDate"
          type="date"
          placeholder="选择作业日期"
          size="small"
          transfer
          :clearable="false"
          style="width: 100%"
        ></DatePicker>
      </div>
      <div slot="footer">
        <Button
          type="text"
          class="m-r-5"
          @click="modifyModal = false"
        >取消</Button>
        <Button
          type="primary"
          :disabled="!actionDate"
          :loading="submitLoading"
          @click="onConfirm"
        >确认</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { ParseDate } from '@/utils/dateFormat'
import { modifyActionDate } from '@/api/msp/workdaySchedule'
export default {
  props: {
    onSuccess: {
      type: Function,
      default: () => { }
    }
  },
  data () {
    return {
      modifyModal: false,
      workdayId: null,
      actionDate: '',
      submitLoading: false
    }
  },
  methods: {
    showModal (data) {
      if (!data) {
        this.$Notice.error({ desc: '非法访问，缺少必要参数' })
        return false
      }
      this.workdayId = data.id
      this.actionDate = data.actionDate
      this.modifyModal = true
    },
    onConfirm () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确认信息无误，并提交？',
        onOk: async () => {
          const postData = {
            workdayId: this.workdayId,
            actionDate: typeof (this.actionDate) === 'string' ? this.this.actionDate : ParseDate(this.actionDate)
          }
          this.submitLoading = true
          const result = await modifyActionDate(postData).finally(() => { this.submitLoading = false })
          if (result && result.success) {
            this.$Notice.success({ desc: '操作成功' })
            this.modifyModal = false
            this.onSuccess()
          }
        }
      })
    }
  }
}
</script>
