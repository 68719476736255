<template>
  <div>
    <div class="platform-buttom-list">
      <div
        class="bottom-button m-r-5"
        @click="goToIndex"
      >
        申请列表
      </div>
      <div class="bottom-button-active">
        申请详情
      </div>
    </div>
    <div>
      <div class="text-16">
        <span class="p-r-20">{{ taskInfo.workTypeName }}</span><span class="p-r-20">{{ taskInfo.startDate+' 至 '+taskInfo.endDate }}</span><span class="p-r-20">{{ taskInfo.code }}</span><span>状态：{{ taskInfo.statusName }}</span>
      </div>
      <Divider />
      <!-- <div class="m-b-10">
        <RadioGroup
          v-model="detailType"
          type="button"
          button-style="solid"
          size="small"
          class="m-b-10"
          @on-change="onChangeType"
        >
          <Radio
            v-for="item in detailTypeArray"
            :key="'dType_'+item.value"
            :label="item.value"
          >{{item.name}}</Radio>
        </RadioGroup>
      </div> -->
      <Row
        :gutter="8"
        class="m-b-10"
      >
        <i-col span="3">
          <DatePicker
            type="daterange"
            placeholder="选择起止日期"
            size="small"
            transfer
            style="width: 100%"
            @on-change="onChangeDates"
          ></DatePicker>
        </i-col>
        <i-col span="3">
          <Select
            size="small"
            placeholder="线路"
            v-model="query.assetId"
            transfer
            clearable
            @on-change="onChangeAsset"
          >
            <i-option
              v-for="item in assetArray"
              :key="'aid_' + item.id"
              :value="item.id"
            >
              {{ item.name }}
            </i-option>
          </Select>
        </i-col>
        <i-col span="3">
          <Select
            size="small"
            placeholder="站点"
            v-model="query.stationId"
            transfer
            clearable
          >
            <i-option
              v-for="item in stationArray"
              :key="'aid_' + item.id"
              :value="item.id"
            >
              {{ item.name }}
            </i-option>
          </Select>
        </i-col>
        <!-- <i-col span="4">
          <i-input
            v-model.trim="query.keyword"
            size="small"
            placeholder="关键字：编号"
          ></i-input>
        </i-col> -->
        <i-col span="8">
          <Button
            type="primary"
            icon="ios-search"
            size="small"
            class="m-r-5"
            @click="onSearch"
          >搜索</Button>
          <Button
            type="success"
            size="small"
            v-if="taskInfo.status<3"
            @click="onConfirmApply"
          >确认已申请</Button>
        </i-col>
      </Row>
      <div>
        <Table
          stripe
          max-height="620"
          size="small"
          :data="tableList"
          :columns="tableColumns"
          :loading="tableLoading"
          @on-selection-change="selectionChange"
        ></Table>
        <div class="paging_style">
          <Page
            size="small"
            :total="total"
            :page-size="query.pageSize"
            show-total
            show-elevator
            show-sizer
            :page-size-opts="pagesizeSetting"
            @on-page-size-change='onChangePageSize'
            :current="query.pageNumber"
            @on-change="onChangePageNum"
          ></Page>
        </div>
      </div>
    </div>
    <set-worker
      ref="setWorker"
      :onSuccess="getTableData"
    />
    <modify-action-date
      ref="modifyActionDate"
      :onSuccess="getTableData"
    />
    <plan-detail ref="planDetail" />
  </div>
</template>

<script>
import { indexMixins } from '@/components/constructionApply/mixins'

import { getWorkdayPage, getWorkdayManageInfo, removeTable, updateStatus } from '@/api/msp/workdayManage'

import SetWorker from './SetWorker.vue'
import ModifyActionDate from './ModifyActionDate.vue'
import PlanDetail from '@/components/constructionApply/components/PlanDetail.vue'

export default {
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  mixins: [indexMixins],
  components: { SetWorker, ModifyActionDate, PlanDetail },
  data () {
    return {
      taskInfo: {},
      assetArray: [],
      stationArray: [],
      showPlanAdd: false,
      query: {
        pageNumber: 1,
        pageSize: 15,
        manageId: null,
        assetId: null,
        startDate: '',
        endDate: '',
        stationId: null,
        supplierCompanyId: null
      },
      tableList: [],
      total: 0,
      tableLoading: false,
      tableColumns: [
        // { type: 'selection', width: 60, align: 'center', fixed: 'left' },
        { title: '作业日期', align: 'center', minWidth: 120, key: 'actionDate' },
        { title: '任务发起方', align: 'center', minWidth: 120, key: 'companyName' },
        { title: '线路', align: 'center', minWidth: 120, key: 'assetName' },
        { title: '站点', align: 'center', minWidth: 120, key: 'stationName' },
        {
          title: '位置',
          align: 'center',
          minWidth: 120,
          render: (h, { row }) => {
            const html = []
            row.positionList.forEach(item => {
              html.push(h('p', `${item.floor}层 - ${item.positionName}`))
            })
            return h('div', html)
          }
        },
        { title: '作业时间段', align: 'center', minWidth: 120, key: 'workTime' },
        { title: '任务数量', align: 'center', minWidth: 100, key: 'taskCount' },
        { title: '任务类型', align: 'center', minWidth: 120, key: 'taskTypes' },
        { title: '施工人数', align: 'center', minWidth: 100, key: 'workerNumber' },
        { title: '施工单位', align: 'center', minWidth: 120, key: 'supplierCompanyName' },
        {
          title: '带班人员',
          align: 'center',
          minWidth: 100,
          key: 'workManagerName',
          render: (h, { row }) => {
            return h('span', row.workManagerName || '/')
          }
        },
        { title: '备注', align: 'center', minWidth: 120, key: 'remark' },

        {
          title: '操作',
          align: 'center',
          minWidth: 260,
          fixed: 'right',
          render: (h, { row }) => {
            const html = [
              h('a', {
                class: ['m-r-5'],
                on: {
                  click: () => {
                    this.showDetail(row)
                  }
                }
              }, '详情')
            ]
            if (this.taskInfo.status < 3) {
              // 非已申请状态
              html.push(
                h('a', {
                  class: ['m-r-5'],
                  on: {
                    click: () => {
                      this.onModifyActionDate(row)
                    }
                  }
                }, '调整作业日期'),
                h('a', {
                  class: ['m-r-5'],
                  on: {
                    click: () => {
                      this.onSetWorker(row)
                    }
                  }
                }, '配置带班人员'),
                h('a', {
                  style: {
                    color: '#ef4f4f'
                  },
                  on: {
                    click: () => {
                      this.onRemove(row)
                    }
                  }
                }, '移除')
              )
            }

            return h('div', html)
          }
        }
      ],
      selectedItems: []

    }
  },
  computed: {
    btnSetWorker () {
      return this.selectedItems.length === 0
    }
  },
  mounted () {
    this.query.manageId = this.id
    this.getAssets()
    this.getTaskInfo()
    this.getTableData()
  },
  methods: {
    goToIndex () {
      this.$router.push({ name: 'construction-apply-manage-index' })
    },
    async getTableData () {
      this.tableLoading = true
      const result = await getWorkdayPage(this.query).finally(() => { this.tableLoading = false })
      if (result && !result.errcode) {
        this.tableList = result.list
        this.total = result.totalRow
      }
    },
    /**
     * 获取详细信息
     */
    async getTaskInfo () {
      const result = await getWorkdayManageInfo({ workdayMangeId: this.id })
      if (result && !result.errcode) {
        this.taskInfo = result
      }
    },
    async getAssets () {
      this.assetArray = await this.getAssetData()
    },
    async onChangeAsset () {
      this.stationArray = await this.getStationData(this.query.assetId)
    },
    onChangeDates (dates) {
      this.query.startDate = dates[0]
      this.query.endDate = dates[1]
    },
    onChangePageNum (curPage) {
      this.query.pageNumber = curPage
      this.getTableData()
    },
    onChangePageSize (curPageSize) {
      this.query.pageNumber = 1
      this.query.pageSize = curPageSize
      this.getTableData()
    },
    onSearch () {
      this.query.pageNumber = 1
      this.getTableData()
    },
    selectionChange (selections) {
      this.selectedItems = selections.map(x => x.id)
    },
    showDetail (obj) {
      this.$nextTick(() => {
        this.$refs.planDetail.showModal(obj)
      })
    },
    onModifyActionDate (obj) {
      this.$nextTick(() => {
        this.$refs.modifyActionDate.showModal(obj)
      })
    },
    onRemove (obj) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要移除改项？',
        onOk: async () => {
          const postData = {
            manageId: this.id,
            tableId: obj.id
          }
          const result = await removeTable(postData)
          if (result && result.success) {
            this.$Notice.success({ desc: '操作成功' })
            this.getTableData()
          }
        }
      })
    },
    /**
     * 确认申请
     */
    onConfirmApply () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定信息无误，并提交？',
        onOk: async () => {
          const postData = {
            manageId: this.id,
            status: 3
          }
          const result = await updateStatus(postData)
          if (result && result.success) {
            this.$Notice.success({ desc: '操作成功' })
            this.getTaskInfo()
            this.getTableData()
          }
        }
      })
    },
    onSetWorker (obj) {
      this.$nextTick(() => {
        this.$refs.setWorker.showModal(obj)
      })
    }

  }
}
</script>
